import React, {  useState, useEffect } from "react";
import EditTodo from "././EditTodo";

const ListTodos = () => {

    const[todos, setTodos] = useState([]);

  const deleteTodo = async id => {
      try {
         await fetch(`http://localhost:4000/dashboard/todos/${id}`, {
          method: "DELETE"
        });

        setTodos(todos.filter(todo => todo.todo_id !== id))
        
      } catch (err) {
        console.error(err.message);        
      }
    }

    const getTodos = async () => {
      try{
        const response = await fetch("http://localhost:4000/dashboard/todos");
        const jsonData = await response.json();
        setTodos(jsonData)
      } catch (err) {
           console.error(err.message) 
      }        
        
       
    }
    useEffect(() => {
        getTodos()
    }, []);

    console.log(todos)

  return (
    <>
      <table class="table-auto mt-5">
        <thead>
          <tr>
            <th>Descripción</th>
            <th>Editar</th>
            <th>Borrar</th>
          </tr>
        </thead>
        <tbody>
 
          {todos.map(todo => (
                <tr key={todo.todo_id}>
                    <td>{todo.description}</td>
                    <td> < EditTodo todo={todo} /> </td>
                    <td>  <button className="border border-amber-600 text-amber-600 py-2 px-4 hover:bg-amber-600 hover:text-white rounded-xl transition-colors" onClick={() => deleteTodo(todo.todo_id)}>
    Borrar
  </button></td>
                </tr>
            ))
          }
        </tbody>
      </table>
    </>
  );
};

export default ListTodos;
