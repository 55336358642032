// import components
import About from "../components/About";
import Skills from "../components/Skills";
import Interview from "../components/Interview";
import Testimonial from "../components/Testimonial";
import Contact from "../components/Contact";


export default function NosotrosPage() {
    return (
        <div className="max-w-[1920px] mx-auto overflow-hidden bg-white">
    
      <About />
      <Skills />
      <Testimonial />
      <Interview />
      <Contact />

    </div>
    )
}