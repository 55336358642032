import React, { useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

//pages
import ComunidadPage from "./pages/ComunidadPage";
import HomePage from "./pages/HomePage";
import NosotrosPage from "./pages/NosotrosPage";
import ProductosPage from "./pages/ProductosPage";
import ServiciosPage from "./pages/ServiciosPage";
import ContactoPage from "./pages/ContactoPage";
import LandingPage from "./pages/LandingPage";
import NotFoundPage from "./pages/NotFoundPage";

//component
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Dashboard from "./components/auth/Dashboard";
import PassRecover from "./components/auth/PassRecover";
import ChangePass from "./components/auth/ChangePass";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Copyright from "./components/Copyright";
//import { ProtectedRoute } from "./components/ProtectedRoute";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const setAuth = (boolean) => {
    setIsAuthenticated(boolean);
  };

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/nosotros" element={<NosotrosPage />} />
        <Route path="/productos" element={<ProductosPage />} />
        <Route path="/servicios" element={<ServiciosPage />} />
        <Route path="/comunidad" element={<ComunidadPage />} />
        <Route path="/contacto" element={<ContactoPage />} />

        <Route path="/auth" element={<LandingPage />}>
          <Route
            path="login"
            index
            element={
              !isAuthenticated ? (
                <Login setAuth={setAuth} />
              ) : (
                <Navigate to="/auth/dashboard" replace />
              )
            }
          />
          <Route
            path="register"
            element={
              !isAuthenticated ? (
                <Register setAuth={setAuth} />
              ) : (
                <Navigate to="/auth/dashboard" replace />
              )
            }
          />
          <Route
            path="dashboard"
            element={
              isAuthenticated ? (
                <Dashboard setAuth={setAuth} />
              ) : (
                <Navigate to="/auth/login" replace />
              )
            }
          />
          {/* <Route element={<ProtectedRoute isAuthenticated={(user?true:false) && user.role.includes('admin')} />}> */}
          {/* <Route element={<ProtectedRoute isAuthenticated />}>
            <Route path="dashboard" element={<Dashboard   setAuth={setAuth} />} />
            {/* 
            <Route path="admin" element={<Admin />} /> */}
          {/* </Route> */} */
          <Route path="pass-recover" element={<PassRecover />} />
          <Route path="pass-change/:token" element={<ChangePass />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
      <Copyright />
    </BrowserRouter>
  );
};

export default App;
