
// import components

import { motion } from "framer-motion";
import { fadeIn } from "../variants";

import { notFoundData } from "../data";

export default function NotFoundPage() {
  const { title, subtitle, image } = notFoundData;
  return (
    <div className="max-w-[1920px] mx-auto overflow-hidden bg-white">
        
  <section id="notFound" className="lg:py-16 xl:pb-[16px] mt-44">
      <div className="container mx-auto ">
        <div className="flex flex-col lg:flex-row gap-x-[70px] items-center">
          <motion.div
            variants={fadeIn("right")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="flex flex-col lg:flex-row flex-1"
          >
            <div
              className="text-[360px] xl:text-[720px] leading-none font-tertiary 
            lg:-tracking-[0.055em] bg-about bg-no-repeat bg-right bg-clip-text text-transparent"
            >
             
          <img src={image} alt="" />
            </div>
          </motion.div>
          <motion.div
            variants={fadeIn("left")}
            initial={"hidden"}
            whileInView={"show"}
            viewport={{ once: false, amount: 0.6 }}
            className="flex-1 h-full xl:mt-48"
          >
            <h2 className="h2">{title}</h2>
            <div className="flex flex-col items-end ">
              <div className="max-w-[530px] text-grey">
                <p className="mb-6">{subtitle}</p>

              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>


</div>
)
};
