import React, {useState} from "react";
import {Link} from "react-router-dom";
import { RiMailFill } from "react-icons/ri";
import { toast } from "react-toastify";

const PassRecover = () => {

  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if([email].includes("")){

      toast.error("😣 El Email es obligatorio", {
         theme: "light",
      });
      return;
    } else {
        toast.success("Sus instrucciones se han enviado", {
            theme: "light",
         });
         return;
    }
    }

  return (
    <section
      id="login"
      className="section mt-[100px] lg:mt-0 lg:py-16 xl:pb-[16px]"
    >
      <div id= "login" className="bg-white p-8 rounded-lg w-full md:w-96">
        <div className="mb-10">
          <h1 className="text-3xl uppercase font-bold text-center">Recuperar Password </h1>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 mb-6">
          <div className="relative ">
            <RiMailFill className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              className="w-full border border-gray-200 outline-none py-2 rounded-lg px-8"
              type="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div>
            <button className="mt-6 bg-sky-600 text-white w-full py-2 px-6 rounded-lg hover:bg-sky-800 transition-colors">
              Enviar
            </button>
          </div>
        </form>
        <div className="text-center">          
            ¿Todavia no tienes cuenta? <Link to="/auth/register" className="text-sky-600 font-medium hover:underline transition-all">Registrarse
          </Link>
        </div>
      </div>
    </section>
  );
};

export default PassRecover;