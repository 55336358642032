import React, {useState} from "react";
import {useParams, useNavigate } from "react-router-dom";
import { RiLock2Fill, RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { toast } from "react-toastify";

const ChangePass = () => {

  const [showPass, setShowPass] = useState(false);
  const [password, setPassword] = useState("");
  const [confpassword, setConfPassword] = useState("")
  const { token } = useParams();
  console.log(token);

  const navigate = useNavigate();

  //TODO: VERIFICAR TOKEN DE UN SOLO USO Y RECUPERAR USER
  if(token !== "546asdfasdfsaf") {
    navigate("/auth/login")

  }


  const handleShowPass = () =>{
    setShowPass(!showPass);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if([password, confpassword].includes("")){

      toast.error("😣 Todos los campos son obligatorios", {
         theme: "light",
      });
      return;
    }

    if(password.length < 6) {
      toast.error("😕 El password debe contener al menos 6 caracteres",{
        theme: "dark",
      })
    }

    if(password !== confpassword ){
      toast.error("😕 Los password deben ser iguales",{
        theme: "light",
      })
    }

    toast.success(" 😀 Se ha registrado",{
      theme: "light",
    })
  }

  return (
    <section
      id="login"
      className="section mt-[100px] lg:mt-0 lg:py-16 xl:pb-[16px]"
    >
      <div id= "login" className="bg-white p-8 rounded-lg w-full md:w-96">
        <div className="mb-10">
          <h1 className="text-3xl uppercase font-bold text-center">Actualizar password </h1>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 mb-6">
         
          <div className="relative ">
            <RiLock2Fill className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              className="w-full border border-gray-200 outline-none py-2 rounded-lg px-8"
              type={showPass ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {showPass ? (<RiEyeFill onClick={handleShowPass} className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:cursor-pointer"/>):<RiEyeOffFill onClick={handleShowPass} className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:cursor-pointer"/>}
            
          </div>

          <div className="relative ">
            <RiLock2Fill className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              className="w-full border border-gray-200 outline-none py-2 rounded-lg px-8"
              type={showPass ? "text" : "password"}
              placeholder="Confirmar password"
              value={confpassword}
              onChange={(e) => setConfPassword(e.target.value)}
            />
            {showPass ? (<RiEyeFill onClick={handleShowPass} className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:cursor-pointer"/>):<RiEyeOffFill onClick={handleShowPass} className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:cursor-pointer"/>}
            
          </div>          
          <div>
            <button className="mt-6 bg-sky-600 text-white w-full py-2 px-6 rounded-lg hover:bg-sky-800 transition-colors">
              Reestablecer password
            </button>
          </div>
        </form>
       
      </div>
    </section>
  );
};


export default ChangePass;