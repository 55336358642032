import React, { useState } from "react";


const InputTodo = () => {
    const [description, setDescription] = useState(""); 
    
    const onSubmitForm = async e => {
        e.preventDefault();
        try {
            const body = {description};
            const response =  await fetch("http://localhost:4000/dashboard/todos", {
                method:"POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(body)
            });

            window.location ="/auth/dashboard";
        } catch (err) {
            console.error(err.message)
            
        }
    }

    return (
 <section
      id="inputTodo"
      className="section mt-[100px] lg:mt-0 lg:py-16 xl:pb-[16px]"
    >
      <div id= "inputTodo" className="bg-white p-8 rounded-lg w-full md:w-96">
        <div className="mb-10">
          <h1 className="text-3xl uppercase font-bold text-center">Ingresar Tarea</h1>
        </div>
        <form onSubmit={onSubmitForm} className="flex flex-col gap-4 mb-6">
          <div className="relative ">
            <div className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              className="w-full border border-gray-200 outline-none py-2 rounded-lg px-8"
              type="text"
              placeholder="tarea nueva"              
              value={description}
              onChange={e => setDescription(e.target.value)}              
            />
          </div>         
          <div>
            <button className="mt-6 bg-sky-600 text-white w-full py-2 px-6 rounded-lg hover:bg-sky-800 transition-colors">
              Ingresar
            </button>
          </div>
        </form>
        
      </div>
    </section>
 );
};

export default InputTodo;
