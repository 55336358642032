// import components
import Faq from "../components/Faq";
import Contact from "../components/Contact";

export default function ServiciosPage() {
    return (
    <div className="max-w-[1920px] mx-auto overflow-hidden bg-white">
          
      <Faq />     
      <Contact />
    </div>
    )
}