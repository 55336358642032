// import components
import Contact from "../components/Contact";

export default function ContactoPage() {
    return (
        <div className="max-w-[1920px] mx-auto overflow-hidden bg-white">
     
      <Contact />

    </div>
    )
}