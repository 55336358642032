import React from "react";
import { Outlet } from "react-router-dom";


export default function LandingPage() {
    return (
    <div className="bg-white max-w-[1920px] mx-auto overflow-hidden">
     
<div className=" bg-gray-200 mt-44 flex items-center justify-center p-6"> <Outlet /></div>     
     
    </div>
    )
}