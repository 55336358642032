// import components
import Contact from "../components/Contact";
import Comunidad from "../components/Comunidad";

export default function ComunidadPage() {
    return (
        <div className="max-w-[1920px] mx-auto overflow-hidden bg-white">
  
      <Comunidad />
      <Contact />
    </div>
    )
}