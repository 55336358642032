import React, {useState} from "react";
import {Link} from "react-router-dom";
import { RiMailFill, RiLock2Fill, RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { toast } from "react-toastify";

const Login = ({setAuth}) => {

  const [showPass, setShowPass] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const {email, password} = inputs

  const onChange = (e) => {
    setInputs({...inputs, [e.target.name] : e.target.value})
  }

  const handleShowPass = () =>{
    setShowPass(!showPass);
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {

      const body = {email, password};

      const response = await fetch("http://localhost:4000/auth/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      const parseRes = await response.json();
 
      localStorage.setItem("token", parseRes.token);
      setAuth(true);

      toast.success(" 😀Bienvenido", {
        theme: "light",
      });
      
    } catch (error) {
      if([email, password].includes("")){

        toast.error("😣 Todos los campos son obligatorios", {
           theme: "light",
        });
        return;
      }
  
      if(password.length < 6) {
        toast.error("😕 El password debe contener al menos 6 caracteres",{
          theme: "dark",
        })
      }
    }
    
  }

  return (
    <section
      id="login"
      className="section mt-[100px] lg:mt-0 lg:py-16 xl:pb-[16px]"
    >
      <div id= "login" className="bg-white p-8 rounded-lg w-full md:w-96">
        <div className="mb-10">
          <h1 className="text-3xl uppercase font-bold text-center">Iniciar Sesión </h1>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 mb-6">
          <div className="relative ">
            <RiMailFill className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              className="w-full border border-gray-200 outline-none py-2 rounded-lg px-8"
              type="email"
              placeholder="E-mail"
              name="email"
              value={email}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="relative ">
            <RiLock2Fill className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              className="w-full border border-gray-200 outline-none py-2 rounded-lg px-8"
              type={showPass ? "text" : "password"}
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => onChange(e)}
            />
            {showPass ? (<RiEyeFill onClick={handleShowPass} className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:cursor-pointer"/>):<RiEyeOffFill onClick={handleShowPass} className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:cursor-pointer"/>}
            
          </div>
          <div className="text-right"><Link className="text-gray-500 hover:text-sky-600 hover:underline transition-colors" to="/auth/pass-recover">
            ¿Olvidaste tu password?
            </Link>
          </div>
          <div>
            <button className="mt-6 bg-sky-600 text-white w-full py-2 px-6 rounded-lg hover:bg-sky-800 transition-colors">
              Ingresar
            </button>
          </div>
        </form>
        <div className="text-center">          
            ¿Todavia no tienes cuenta? <Link to="/auth/register" className="text-sky-600 font-medium hover:underline transition-all">Registrarse
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Login;
