import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  RiMailFill,
  RiLock2Fill,
  RiEyeFill,
  RiEyeOffFill,
  RiUser3Fill,
} from "react-icons/ri";
import { toast } from "react-toastify";

const Register = ({setAuth}) => {
  const [showPass, setShowPass] = useState(false);

  const [inputs, setInputs] = useState({
    name: "",
    surname: "",
    email: "",
    password: "",
  });

  const {name, surname, email, password, confpassword} = inputs

  const onChange = (e) => {
    setInputs({...inputs, [e.target.name] : e.target.value})
  }

  const handleShowPass = () => {
    setShowPass(!showPass);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      
      const body = { name, surname, email, password, role:"" };
      

      const response = await fetch("http://localhost:4000/auth/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      const parseRes = await response.json();
    
      localStorage.setItem("token", parseRes.token);
      setAuth(true);

      toast.success(" 😀 Usuario registrado", {
        theme: "light",
      });
    } catch (error) {
      if ([name, surname, email, password, confpassword].includes("")) {
        toast.error("😣 Todos los campos son obligatorios", {
          theme: "light",
        });
        return;
      }

      if (password.length < 6) {
        toast.error("😕 El password debe contener al menos 6 caracteres", {
          theme: "dark",
        });
      }

      if (password !== confpassword) {
        toast.error("😕 Los password deben ser iguales", {
          theme: "light",
        });
      }
    }
  };

  return (
    <section
      id="register"
      className="section mt-[100px] lg:mt-0 lg:py-16 xl:pb-[16px]"
    >
      <div id="register" className="bg-white p-8 rounded-lg w-full md:w-96">
        <div className="mb-10">
          <h1 className="text-3xl uppercase font-bold text-center">
            Registrarse{" "}
          </h1>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div className="relative ">
            <RiUser3Fill className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              className="w-full border border-gray-200 outline-none py-2 rounded-lg px-8"
              type="text"
              placeholder="Nombre(s)"
              name="name"
              value={name}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="relative ">
            <RiUser3Fill className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              className="w-full border border-gray-200 outline-none py-2 rounded-lg px-8"
              type="text"
              placeholder="Apellido(s)"
              name="surname"
              value={surname}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="relative ">
            <RiMailFill className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              className="w-full border border-gray-200 outline-none py-2 rounded-lg px-8"
              type="email"
              name="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="relative ">
            <RiLock2Fill className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              className="w-full border border-gray-200 outline-none py-2 rounded-lg px-8"
              type={showPass ? "text" : "password"}
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => onChange(e)}
            />
            {showPass ? (
              <RiEyeFill
                onClick={handleShowPass}
                className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:cursor-pointer"
              />
            ) : (
              <RiEyeOffFill
                onClick={handleShowPass}
                className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:cursor-pointer"
              />
            )}
          </div>
          <div className="relative ">
            <RiLock2Fill className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-400" />
            <input
              className="w-full border border-gray-200 outline-none py-2 rounded-lg px-8"
              type={showPass ? "text" : "password"}
              placeholder="Confirmar password"
              name="confpassword"
              value={confpassword}
              onChange={(e) => onChange(e)}
            />
            {showPass ? (
              <RiEyeFill
                onClick={handleShowPass}
                className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:cursor-pointer"
              />
            ) : (
              <RiEyeOffFill
                onClick={handleShowPass}
                className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:cursor-pointer"
              />
            )}
          </div>
          <div>
            <button className="mt-6 mb-6 bg-sky-600 text-white w-full py-2 px-6 rounded-lg hover:bg-sky-800 transition-colors">
              Crear cuenta
            </button>
          </div>
        </form>
        <div className="text-center">
          ¿Ya tienes cuenta?{" "}
          <Link
            to="/auth/login"
            className="text-sky-600 font-medium hover:underline transition-all"
          >
            Ingresar
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Register;
