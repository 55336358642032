import React, { Fragment, useState, useEffect } from "react";
import InputTodo from "../dashboard/InputTodo";
import ListTodos from "../dashboard/ListTodos";

const Dashboard = ({ setAuth }) => {
  const [name, setName] = useState("");

  async function getName() {
    try {
      const response = await fetch("http://localhost:4000/dashboard/", {
        method: "POST",
        headers: { token: localStorage.token },
      });

      const parseRes = await response.json();

      setName(parseRes.user_name);
    } catch (err) {
      console.error(err.message);
    }
  }

  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    setAuth(false);
  };

  useEffect(() => {
    getName();
  });

  return (
    <Fragment className="flex flex-col lg:flex-row gap-x-[70px] items-center">
      
      <div className="flex flex-col">
        <h1>Hola {name}!!!</h1>        
        <div>
        <h2>Bienvenida/o a tu Dashboard</h2>
        <div>
        <button
          onClick={(e) => logout(e)}
          className="mt-6 bg-sky-600 text-white w-full py-2 px-6 rounded-lg hover:bg-sky-800 transition-colors"
        >
          Logout
        </button>
      </div>

      </div>

      <div>
        <InputTodo />
        <ListTodos />
      </div>
      </div>



    </Fragment>
  );
};

export default Dashboard;
